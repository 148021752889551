import { Component } from "@angular/core";

@Component({
  selector: "app-docs",
  templateUrl: "./docs.component.html",
  styleUrls: ["./docs.component.css"],
})
export class DocsComponent {
  constructor() {}
  currentVersion = "1.0";
}
