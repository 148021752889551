export const environment = {
  production: false,
  base_url: "https://internal.api.phoenixhealthgh.co/system/",
  admin_base_url: "https://internal.api.phoenixhealthgh.co/admin/",
  udr_base_url: "https://internal.api.phoenixhealthgh.co/underwriting/",
  cusmgt_base_url: "https://internal.api.phoenixhealthgh.co/customers/",
  provider_base_url: "https://internal.api.phoenixhealthgh.co/provider/",
  claims_base_url: "https://internal.api.phoenixhealthgh.co/claims/",
  micro_base_url: "https://internal.api.phoenixhealthgh.co/micro/",
  access_base_url: "https://internal.api.phoenixhealthgh.co/access/",
  policy_base_url: "https://internal.api.phoenixhealthgh.co/policy/",
  premiumwritten_base_url:
    "https://internal.api.phoenixhealthgh.co/premiumwritten/",
};
