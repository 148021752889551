import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "../services/base.service";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CheckUserAPIResponse } from "src/app/models/http-response";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {}
  httpOptions = {
    headers: new HttpHeaders({ "Authorization-Type": BaseService.getToken() }),
  };

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.http.get(environment.base_url + "login", this.httpOptions).pipe(
      map((res) => {
        if (res["status"] === "507") {
          sessionStorage.setItem("roles", res["roles"]);
          this.authService.isLoggedIn = true;
          return true;
        } else {
          this.authService.isLoggedIn = false;
          this.router.navigate(["/login"]);
          return false;
        }
      })
    );
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }

  checkLogin(): void {
    this.authService.checkUser().subscribe((data: CheckUserAPIResponse) => {
      if (data.status === "507") {
        sessionStorage.setItem("roles", data.roles);
        this.authService.isLoggedIn = true;
        return true;
      } else {
        this.authService.isLoggedIn = false;
        this.router.navigate(["/login"]);
        return false;
      }
    });
  }
}
