import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FooterComponent } from "./common/components/footer/footer.component";
import { HeaderComponent } from "./common/components/header/header.component";
import { HomeComponent } from "./common/components/home/home.component";
import { LoginComponent } from "./common/components/login/login.component";
import { MenuComponent } from "./common/components/menu/menu.component";
import { PageNotFoundComponent } from "./common/components/page-not-found/page-not-found.component";
import { BaseService } from "./common/services/base.service";
import { AuthService } from "./common/services/auth.service";
import { MenuService } from "./common/services/menu.service";
import { ToastrModule } from "ngx-toastr";
import { MaterialModule } from "./modules/material/material.module";
import { CommModule } from "./modules/common/common.module";
import { DocsComponent } from "./common/components/docs/docs.component";
import { AgeCalcDirective } from "./common/directives/age-calc.directive";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./common/components/profile/profile.component";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    PageNotFoundComponent,
    DocsComponent,
    AgeCalcDirective,
    ProfileComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    CommModule,
    ToastrModule.forRoot(),
  ],
  providers: [BaseService, AuthService, MenuService],
  bootstrap: [AppComponent],
})
export class AppModule {}
