<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <div class="card-box">

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="#home" data-toggle="tab" aria-expanded="false" class="nav-link active">
                Recent Apps
              </a>
            </li>
            <li class="nav-item">
              <a href="#profile" data-toggle="tab" aria-expanded="true" class="nav-link ">
                My Notification
              </a>
            </li>
          </ul>
          <div class="tab-content" style=" background: #e8e8e8; padding: 10px;">
            <div class="tab-pane show active" id="home">
              <div class="row">
                <div class="col-md-12">
                  <div class="menu-grid">

                    <div class="menu-item" *ngIf="viewCustMgt" routerLink="/app/customers">
                      <div class="avatar-lg rounded-circle bg-soft-primary border-primary border">
                        <i class="fas fa-handshake font-22 avatar-title text-primary"></i>
                      </div>

                      <span>Customer Management</span>
                    </div>


                    <div class="menu-item" *ngIf="viewProviderMgt" routerLink="/app/providers">
                      <div class="avatar-lg rounded-circle bg-soft-success border-success border">
                        <i class="fas fa-hospital-alt font-22 avatar-title text-success"></i>
                      </div>

                      <span>Provider Management</span>
                    </div>

                    <div class="menu-item" *ngIf="viewClaimsMgt" routerLink="/app/claims">
                      <div class="avatar-lg rounded-circle bg-soft-info border-info border">
                        <i class="fas fa-file-alt font-22 avatar-title text-info"></i>
                      </div>

                      <span>Claims <br /> Management</span>
                    </div>


                    <div class="menu-item" *ngIf="viewUnderW" routerLink="/app/underwriting">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="fas fa-pencil-ruler font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Underwriting</span>
                    </div>

                    <div class="menu-item" *ngIf="viewPolicyMgt" routerLink="/app/policy">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="fas fa-file-invoice font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Policy Management</span>
                    </div>

                    <div class="menu-item" *ngIf="viewMicroIns" routerLink="/app/micro">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="fas fa-file-invoice font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Micro-Insurance</span>
                    </div>

                    <div class="menu-item" *ngIf="viewAccess" routerLink="/app/access">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="fas fa-file-invoice font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Access89</span>
                    </div>



                  </div>
                </div>

              </div>

              <div class="row">


                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewCustMgt">
                  <div class="widget-rounded-circle card-box" routerLink="/app/customers">
                    <div class="row">
                      <div class="col-8">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Customer Management</span></h3>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="avatar-lg rounded-circle bg-soft-primary border-primary border">
                          <i class="fas fa-handshake font-22 avatar-title text-primary"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewProviderMgt">
                  <div class="widget-rounded-circle card-box" routerLink="/app/providers">
                    <div class="row">
                      <div class="col-8">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Provider Management</span></h3>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="avatar-lg rounded-circle bg-soft-success border-success border">
                          <i class="fas fa-hospital-alt font-22 avatar-title text-success"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewClaimsMgt">
                  <div class="widget-rounded-circle card-box" routerLink="/app/claims">
                    <div class="row">
                      <div class="col-7">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Claims Management</span></h3>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="avatar-lg rounded-circle bg-soft-info border-info border">
                          <i class="fas fa-file-alt font-22 avatar-title text-info"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" >
                  <div class="widget-rounded-circle card-box" routerLink="/app/care">
                    <div class="row">
                      <div class="col-6">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Customer Care</span></h3>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                          <i class="fas fa-users font-22 avatar-title text-warning"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewUnderW">
                  <div class="widget-rounded-circle card-box" routerLink="/app/underwriting">
                    <div class="row">
                      <div class="col-7">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Underwriting</span></h3>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                          <i class="fas fa-pencil-ruler font-22 avatar-title text-warning"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewPolicyMgt">
                  <div class="widget-rounded-circle card-box" routerLink="/app/policy">
                    <div class="row">
                      <div class="col-7">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Policy Management</span></h3>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                          <i class="fas fa-file-invoice font-22 avatar-title text-warning"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewMicroIns">
                  <div class="widget-rounded-circle card-box" routerLink="/app/micro">
                    <div class="row">
                      <div class="col-7">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Micro-Insurance</span></h3>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                          <i class="fas fa-file-invoice font-22 avatar-title text-warning"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-6 col-xl-3" *ngIf="viewAccess">
                  <div class="widget-rounded-circle card-box" routerLink="/app/access">
                    <div class="row">
                      <div class="col-7">
                        <div class="text-left">
                          <h3 class="text-dark mt-1"><span>Access89</span></h3>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                          <i class="fas fa-file-invoice font-22 avatar-title text-warning"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> -->

              </div>

            </div>
            <div class="tab-pane" id="profile">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table" *ngIf="allNotifs.length !== 0">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Details</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let info of allNotifs">
                          <td>{{info.notificationsubject}} <span class="badge badge-info"
                            *ngIf="info.notificationstatus === 'unread'">Unread</span></td>
                          <td [innerHTML]="info.notificationbody"></td>
                          <td>{{ timeAgo(info.datecreated) }}</td>
                          <td *ngIf="info.notificationstatus === 'unread'">
                            <button class="btn btn-sm btn-primary" (click)="markAsRead(info.id)"> Mark as read</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5 class="text-center" *ngIf="allNotifs?.length === 0">You have no notifications</h5>
                    <h5 class="text-center" *ngIf="loadAllNotifs">Loading...<i class="fa fa-spinner fa-spin"></i>
                    </h5>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
