import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  static domain: string;
  static base_url: string;
  static admin_base_url: string;
  static udr_base_url: string;
  static cusmgt_base_url: string;
  static provider_base_url: string;
  static claims_base_url: string;
  static policy_base_url: string;

  constructor() {
    BaseService.domain = "http://localhost";
    BaseService.base_url = `${BaseService.domain}:4360/system/`;
    BaseService.admin_base_url = `${BaseService.domain}:4360/admin/`;
    BaseService.udr_base_url = `${BaseService.domain}:4360/underwriting/`;
    BaseService.cusmgt_base_url = `${BaseService.domain}:4360/customers/`;
    BaseService.provider_base_url = `${BaseService.domain}:4360/provider/`;
    BaseService.claims_base_url = `${BaseService.domain}:4360/claims/`;
    BaseService.policy_base_url = `${BaseService.domain}:4360/policy/`;
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static isLoggedIn() {
    if (localStorage.getItem("token") != null) {
      return true;
    } else {
      return false;
    }
  }
}
