<body class="authentication-bg authentication-bg-pattern">

  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card bg-pattern">

            <div class="card-body p-4">

              <div class="text-center w-75 m-auto">
                <h3 class="text-center"> Phoenix Health</h3>

                <p class="text-muted mb-4 mt-3">Enter your email address and password to access admin panel.</p>
              </div>

              <form>

                <div class="alert alert-danger" role="alert" *ngIf="invalid">
                  <i class="bi bi-exclamation-triangle-fill mr-2"></i>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <strong>Error!</strong> Invalid username and password.
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="inactive">
                  <i class="bi bi-exclamation-triangle-fill mr-2"></i>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <strong>Ooops!</strong> Your account is inactive.
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="unknown">
                  <i class="bi bi-exclamation-triangle-fill mr-2"></i>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <strong>Error!</strong> Something Unexpected happened. Please try again.
                </div>

                <div class="form-group mb-3">
                  <label for="emailaddress">Email address</label>
                  <input class="form-control" type="email" id="emailaddress" [(ngModel)]="user.email" required=""
                    name="email" placeholder="Enter your email">
                </div>

                <div class="form-group mb-3">
                  <label for="password">Password</label>
                  <input class="form-control" type="password" required="" id="password" [(ngModel)]="user.password"
                    name="password" placeholder="Enter your password">
                </div>

                <div class="form-group mb-0 text-center">
                  <button class="btn btn-primary btn-block waves-effect waves-light" id="btngoin" (click)="userLogin()"
                    type="submit" *ngIf="!loading">Log
                    In</button>
                  <button class="btn btn-primary btn-block waves-effect waves-light" disabled=true id="btngoin"
                    type="submit" *ngIf="loading">
                    <i class="bi bi-arrow-repeat fa-spin"></i> Logging
                    In...</button>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</body>
