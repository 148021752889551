import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { LoginAPIResponse } from "src/app/models/http-response";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private loginService: AuthService) {}
  user: User;
  invalid = false;
  inactive = false;
  unknown = false;
  loading = false;

  ngOnInit() {
    this.user = <User>{};
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("profile");
  }

  userLogin() {
    this.loading = true;
    this.invalid = false;
    this.inactive = false;
    this.unknown = false;

    this.loginService.login(this.user).subscribe((data: LoginAPIResponse) => {
      if (data.status === "500") {
        localStorage.setItem("name", data.name);
        localStorage.setItem("token", data.token);
        localStorage.setItem("profile", data.profile);

        if (data.profile === "Vetting") {
          this.router.navigateByUrl("/app/claims/vetting");
        } else {
          this.router.navigateByUrl("/app");
        }
      } else if (data.status === "310") {
        this.loading = false;
        this.invalid = true;
      } else if (data.status === "314") {
        this.loading = false;
        this.inactive = true;
      } else if (data.status === "200") {
        this.loading = false;
        this.unknown = true;
      }
    });
  }
}
