import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./common/components/login/login.component";
import { HomeComponent } from "./common/components/home/home.component";
import { MenuComponent } from "./common/components/menu/menu.component";
import { PageNotFoundComponent } from "./common/components/page-not-found/page-not-found.component";
import { AuthGuard } from "./common/guards/auth.guard";
import { DocsComponent } from "./common/components/docs/docs.component";
import { ProfileComponent } from "./common/components/profile/profile.component";

export const loadPolicyModule = {
  policyModule: () =>
    import("./modules/policy/policy.module").then((m) => m.PolicyModule),
};

export const loadAdminModule = {
  adminModule: () =>
    import("./modules/admin/admin.module").then((m) => m.AdminModule),
};

export const loadClaimsModule = {
  claimsmgtModule: () =>
    import("./modules/claimsmgt/claimsmgt.module").then(
      (m) => m.ClaimsmgtModule
    ),
};

export const loadCustomerModule = {
  customermgtModule: () =>
    import("./modules/customermgt/customermgt.module").then(
      (m) => m.CustomermgtModule
    ),
};

export const loadProviderModule = {
  providermgtModule: () =>
    import("./modules/providermgt/providermgt.module").then(
      (m) => m.ProvidermgtModule
    ),
};

export const loadUnderwritingModule = {
  underwritingModule: () =>
    import("./modules/underwriting/underwriting.module").then(
      (m) => m.UnderwritingModule
    ),
};

export const loadMicroModule = {
  microModule: () =>
    import("./modules/micro/micro.module").then((m) => m.MicroModule),
};

export const loadAccessModule = {
  accessModule: () =>
    import("./modules/access/access.module").then((m) => m.AccessModule),
};

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "docs", component: DocsComponent },
  {
    path: "app",
    component: HomeComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: "", redirectTo: "menu", pathMatch: "full" },
      { path: "menu", component: MenuComponent },
      { path: "profile", component: ProfileComponent },
      { path: "admin", loadChildren: loadAdminModule.adminModule },
      { path: "claims", loadChildren: loadClaimsModule.claimsmgtModule },
      { path: "customers", loadChildren: loadCustomerModule.customermgtModule },
      { path: "providers", loadChildren: loadProviderModule.providermgtModule },
      {
        path: "underwriting",
        loadChildren: loadUnderwritingModule.underwritingModule,
      },
      { path: "policy", loadChildren: loadPolicyModule.policyModule },
      { path: "micro", loadChildren: loadMicroModule.microModule },
      { path: "access", loadChildren: loadAccessModule.accessModule },
      { path: "**", component: PageNotFoundComponent },
    ],
  },
  { path: "**", component: PageNotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
