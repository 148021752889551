import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "./base.service";
import { User } from "src/app/models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ "Authorization-Type": BaseService.getToken() }),
  };

  myInfo() {
    return this.http.get(
      environment.base_url + "users/myinfo",
      this.httpOptions
    );
  }

  checkuser() {
    return this.http.get(environment.base_url + "check", this.httpOptions);
  }

  getAllNotifs() {
    return this.http.get(
      environment.base_url + "notifications",
      this.httpOptions
    );
  }

  getNewNotifs() {
    return this.http.get(
      environment.base_url + "notifications/new",
      this.httpOptions
    );
  }

  readNotif(notificationid: string) {
    return this.http.post(
      environment.base_url + "notifications",
      JSON.stringify({ id: notificationid }),
      this.httpOptions
    );
  }

  readAllNotif() {
    return this.http.post(
      environment.base_url + "notifications/readall",
      JSON.stringify(""),
      this.httpOptions
    );
  }

  getMyInfo() {
    return this.http.get(
      environment.base_url + "settings/info",
      this.httpOptions
    );
  }

  getMyProfile() {
    return this.http.get(environment.base_url + "profile", this.httpOptions);
  }

  updateProfileInfo(user: User) {
    return this.http.post(
      environment.base_url + "profile",
      JSON.stringify(user),
      this.httpOptions
    );
  }

  updateProfilePreference(user: User) {
    return this.http.post(
      environment.base_url + "profile/preference",
      JSON.stringify(user),
      this.httpOptions
    );
  }

  updateProfilePwd(user: User) {
    return this.http.post(
      environment.base_url + "profile/pwd",
      JSON.stringify(user),
      this.httpOptions
    );
  }

  updateInfo(user: User) {
    return this.http.post(
      environment.base_url + "settings/info",
      JSON.stringify(user),
      this.httpOptions
    );
  }

  updatePass(user: User) {
    return this.http.post(
      environment.base_url + "settings/password",
      JSON.stringify(user),
      this.httpOptions
    );
  }

  getMyActivity() {
    return this.http.get(environment.base_url + "activity", this.httpOptions);
  }
}
