import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PullImgDirective } from "../../common/directives/pull-img.directive";
import { CalcAgePipe } from "../../common/pipes/calc-age.pipe";

@NgModule({
  declarations: [PullImgDirective, CalcAgePipe],
  imports: [CommonModule],
  exports: [PullImgDirective, CalcAgePipe],
})
export class CommModule {}
