import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { APIResponse } from "src/app/models/http-response";
import { MenuService } from "src/app/common/services/menu.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  constructor(
    private menuService: MenuService,
    private toastr: ToastrService
  ) {}
  users: User[] = [];
  user: User = <User>{};
  loading = false;
  saving = true;

  ngOnInit(): void {
    this.getProfileInfo();
  }

  getProfileInfo() {
    this.saving = true;

    this.menuService.getMyProfile().subscribe((data: User) => {
      this.saving = false;

      this.user = data;
    });
  }

  updateProfileInfo() {
    this.saving = true;

    this.menuService
      .updateProfileInfo(this.user)
      .subscribe((data: APIResponse) => {
        this.saving = false;

        if (data.status === "507") {
          this.getProfileInfo();
          this.toastr.success(
            "Profile info updated successfully",
            "Successful"
          );
        } else {
          this.toastr.error("Something went wrong. Please try again", "Error");
        }
      });
  }

  updateNotificationPref() {
    this.saving = true;

    this.menuService
      .updateProfilePreference(this.user)
      .subscribe((data: APIResponse) => {
        this.saving = false;

        if (data.status === "507") {
          this.getProfileInfo();
          this.toastr.success(
            "Profile info updated successfully",
            "Successful"
          );
        } else {
          this.toastr.error("Something went wrong. Please try again", "Error");
        }
      });
  }
}
