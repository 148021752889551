import { Injectable } from "@angular/core";
import { User } from "src/app/models/user";
import { Router } from "@angular/router";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  isLoggedIn = false;

  login(userCredentials: User) {
    return this.http.put(
      environment.base_url + "login",
      JSON.stringify(userCredentials)
    );
  }

  checkUser() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization-Type": BaseService.getToken(),
      }),
    };

    return this.http.get(environment.base_url + "check", httpOptions);
  }

  logout(): void {
    this.isLoggedIn = false;
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    this.router.navigateByUrl("/login");
  }
}
